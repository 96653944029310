import {useSiteMetadata} from "../hooks";
import Layout from "../components/Layout";
import React from "react";
import TextPolicyPage from "../components/TextPolicyPage";
import {TERMS_OF_USE} from "../constants/terms-of-use";
import {graphql, useStaticQuery} from "gatsby";

const TermsOfUseTemplate = ({ location }) => {
    const { subtitle: siteSubtitle } = useSiteMetadata();
    const { bg } = useStaticQuery(graphql`
      query {
          bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "terms-of-use" } }) {
              nodes {
                  name
                  publicURL
              }
          }
      }
  `);


  return (
        <Layout title="Terms of use"
                darkBreadcrumbs
                description={siteSubtitle}
                location={location}
                socialImage={bg.nodes[0].publicURL}>
            <TextPolicyPage page={TERMS_OF_USE}/>
        </Layout>
    );
};

export default TermsOfUseTemplate;

